import { MDCTopAppBar } from '@material/top-app-bar'
import { MDCMenu, Corner } from '@material/menu'
import { MDCList } from '@material/list'
import { MDCTextField } from '@material/textfield'

const topAppBarElement = document.querySelector('.mdc-top-app-bar')

// eslint-disable-next-line no-unused-vars
const topAppBar = new MDCTopAppBar(topAppBarElement)
const allTextFields = document.querySelectorAll('.mdc-text-field')
for (const textFieldEl of allTextFields) {
  // eslint-disable-next-line no-unused-vars
  const textfield = new MDCTextField(textFieldEl)
}

const menuEl = document.querySelector('.mdc-menu')
if (menuEl) {
  const menu = new MDCMenu(menuEl)
  menu.setAnchorCorner(Corner.BOTTOM_END)
  window.menuClick = function () {
    menu.open = true
  }
}

if (document.querySelector('.mdc-list')) {
  // eslint-disable-next-line no-unused-vars
  const list = new MDCList(document.querySelector('.mdc-list'))
}
